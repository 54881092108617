import React, { useEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";


// Activating fake backend
fakeBackend();

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  useEffect(() => {
    document.documentElement.setAttribute('data-sidebar-size', 'sm')
   }, [])
   
  return (
    <React.Fragment>
      <MsalProvider instance={pca}>
      
          <Route />
      </MsalProvider>
    </React.Fragment>
  );
}

export default App;
