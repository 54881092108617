import React, { useState, useEffect, useMemo } from "react";
// import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Details, UnderstandingFragment } from "Mike/models/response";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getRandomInt } from "Mike/utils/utils";
import icon from "../../../src/assets/images/svg/crypto-icons/abt.svg";
import icon1 from "../../../src/assets/images/svg/crypto-icons/tnt.svg";
import icon2 from "../../../src/assets/images/svg/crypto-icons/tomo.svg";
import icon3 from "../../../src/assets/images/svg/crypto-icons/tzc.svg";
import icon4 from "../../../src/assets/images/svg/crypto-icons/uni.svg";
import "./scss/Understanding.scss";

interface CardData {
  [key: string]: { title: string; description: string; tags: string[] };
}

const cardData: CardData = {
  problem: {
    title: "Problem",
    description:
      "Issues or pain points that the app aims to address or resolve for its users.",
    tags: ["problem", "pain point", "issues"],
  },
  alternatives: {
    title: "Alternatives",
    description:
      "A list of other existing apps or solutions that are similar to our product, providing potential customers with alternative options they might consider.",
    tags: ["alternatives", "similar apps"],
  },
  solutions: {
    title: "Solutions",
    description:
      "The solutions the app provides to solve the identified problems, highlighting its key functionalities and features.",
    tags: ["solutions", "functionalities", "features"],
  },
  keyMetrics: {
    title: "Key Metrics",
    description:
      "Critical indicators that measure the app's success and performance. These metrics are essential for evaluating the app's impact and growth.",
    tags: ["performance", "success", "metrics"],
  },
  valueProposition: {
    title: "Value Proposition",
    description:
      "Benefits and advantages that the app offers to its users, making it stand out from the competition.",
    tags: ["value proposition", "benefits", "advantages"],
  },
  highLevelConcepts: {
    title: "High Level Concepts",
    description:
      "Overarching ideas and concepts that describe the fundamental purpose and functionality of the app in a broader context.",
    tags: ["high level concepts", "purpose", "functionality"],
  },
  unfairAdvantage: {
    title: "Unfair Advantage",
    description:
      "Factors that give the app a competitive edge that cannot be easily replicated by competitors, often involving proprietary technology, exclusive partnerships, or unique expertise.",
    tags: ["unfair advantage", "proprietary technology", "partnerships"],
  },
  costStructure: {
    title: "Cost Structure",
    description:
      "Primary costs associated with developing, maintaining, and operating the app. This includes both fixed and variable expenses.",
    tags: ["cost structure", "fixed costs", "variable costs"],
  },
  earlyAdopters: {
    title: "Early Adopters",
    description:
      "Who are likely to use the app shortly after its launch. These are often individuals or groups who are most in need of the app’s features and benefits.",
    tags: ["early adopters", "individuals", "groups"],
  },
  revenueStreams: {
    title: "Revenue Streams",
    description:
      "Ways through which the app generates revenue. This can include various monetization strategies such as subscriptions, in-app purchases, and ads.",
    tags: ["revenue streams", "subscriptions", "in-app purchases", "ads"],
  },
  customerSegments: {
    title: "Customer Segments",
    description:
      "Distinct groups of people or organizations that the app aims to serve.",
    tags: ["customer segments", "groups"],
  },
};

function getTitle(key: string) {
  return cardData[key]?.title;
}
function getDescription(key: string) {
  return cardData[key]?.description;
}
function shouldIgnore(key: string) {
  return key === "appName" || key === "summary";
}

type UnderstandingProps = {
  understanding: UnderstandingFragment | null | undefined;
  setUnderstanding: React.Dispatch<React.SetStateAction<UnderstandingFragment | null | undefined>>
};

const Understanding = ({ understanding, setUnderstanding }: UnderstandingProps) => {
  const [modalGrid, setModalGrid] = useState<boolean>(false);
  const [deleteModalGrid, setDeleteModalGrid] = useState<boolean>(false);
  const iconSet = [
    "bx bx-shekel",
    "bx bx-diamond",
    "bx bx-align-justify",
    "bx bx-atom",
    "bx bx-unite",
  ];
  const imageSet = [icon1, icon2, icon3, icon4];
  const [title, setTitle] = useState<string>("Title");
  const [description, setDescription] = useState<string[]>([]);
  const [tags, setTags] = useState<string>("");
  // const [value, setValue] = useState<UnderstandingFragment>({
  //   title: "",
  //   points: [""],
  //   tags: [""],
  // });
  const [editCardId, setEditCardId] = useState<string>("");
  document.title = "Understanding | Michelangelo";

  function getUnderstandingProperty(key: string) {
    switch (key) {
      case "problem":
        return understanding?.problem;
      case "alternatives":
        return understanding?.alternatives;
      case "costStructure":
        return understanding?.costStructure;
      case "earlyAdopters":
        return understanding?.earlyAdopters;
      case "customerSegments":
        return understanding?.customerSegments;
      case "keyMetrics":
        return understanding?.keyMetrics;
      case "solutions":
        return understanding?.solutions;
      case "unfairAdvantage":
        return understanding?.unfairAdvantage;
      case "valueProposition":
        return understanding?.valueProposition;
      case "highLevelConcepts":
        return understanding?.highLevelConcepts;
      case "revenueStreams":
        return understanding?.revenueStreams;
    }
    return null;
  }

  function isIncludeProperty(key: string) {
    if (getUnderstandingProperty(key)?.length === 0) return false;
    return true;
  }
  function handleList(point: string, index: number) {
    let data = [...description];
    if (!data) return;
    data[index] = point;
    setDescription(data);
    console.log("the data: ", data);
  }

  function addToList() {
    console.log("addToList");
    let data = [...description];
    if (!data) return;
    data.push("");
    setDescription(data);
  }

  function handleEdit(key: string) {
    setModalGrid(true);
    setEditCardId(key);
    const data = getUnderstandingProperty(key);
    if (understanding && data) {
      setDescription(data);
    }
  }

  function removeList(index: number) {
    let data = [...description];
    if (!data) return;
    data.splice(index, 1);
    setDescription(data);
  }

  function updateCardData() {
    let newData: UnderstandingFragment | null | undefined = { ...understanding };
    if (!newData) return;
    switch (editCardId) {
      case "problem":
        newData.problem = description;
        break;
      case "alternatives":
        newData.alternatives = description;
        break;
      case "costStructure":
        newData.costStructure = description;
        break;
      case "earlyAdopters":
        newData.earlyAdopters = description;
        break;
      case "customerSegments":
        newData.customerSegments = description;
        break;
      case "keyMetrics":
        newData.keyMetrics = description;
        break;
      case "solutions":
        newData.solutions = description;
        break;
      case "unfairAdvantage":
        newData.unfairAdvantage = description;
        break;
      case "valueProposition":
        newData.valueProposition = description;
        break;
      case "highLevelConcepts":
        newData.highLevelConcepts = description;
        break;
      case "revenueStreams":
        newData.revenueStreams = description;
        break;
    }
    setUnderstanding(newData);
    setModalGrid(false);
  }

  function openDeleteModal(key: string) {
    setDeleteModalGrid(true);
    setEditCardId(key);
  }
  function handleDelete() {
    let newData: UnderstandingFragment | null | undefined = { ...understanding };
    if (!newData) return;
    console.log("handleDelete");
    switch (editCardId) {
      case "problem":
        newData.problem = [];
        break;
      case "alternatives":
        newData.alternatives = [];
        break;
      case "costStructure":
        newData.costStructure = [];
        break;
      case "earlyAdopters":
        newData.earlyAdopters = [];
        break;
      case "customerSegments":
        newData.customerSegments = [];
        break;
      case "keyMetrics":
        newData.keyMetrics = [];
        break;
      case "solutions":
        newData.solutions = [];
        break;
      case "unfairAdvantage":
        newData.unfairAdvantage = [];
        break;
      case "valueProposition":
        newData.valueProposition = [];
        break;
      case "highLevelConcepts":
        newData.highLevelConcepts = [];
        break;
      case "revenueStreams":
        newData.revenueStreams = [];
        break;
    }
    setUnderstanding(newData);
    setDeleteModalGrid(false)
  }



  return (
    <>
      {/* <Row> */}
      <Row id="job-list">
        <Col lg={4} md={6} id="job-widget" style={{ minWidth: '350px' }}>
          <Card className="card-height-100 bg-secondary bg-job">
            <CardBody className="p-5">
              <h2 className="lh-base text-white">{understanding?.appName}</h2>
              <h3 className="lh-base text-white text-opacity-75">{understanding?.summary}</h3>
              {/* <p className="text-white text-opacity-75 mb-0 fs-14">{understanding?.summary}</p> */}
              {/* <div className="mt-5 pt-2">
                    <button type="button" className="btn btn-light w-100">
                      View More{" "}
                      <i className="ri-arrow-right-line align-bottom"></i>
                    </button>
                  </div> */}
            </CardBody>
          </Card>
        </Col>
        {understanding &&
          Object.keys(understanding)
            .filter((item) => !shouldIgnore(item))
            .filter((item) => isIncludeProperty(item))
            .map((key: string, index: number) => (
              <Col className="d-flex" lg={4} md={6} style={{ minWidth: '350px' }}>
                <Card style={{ flex: "1 1 auto" }}>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="avatar-sm mb-4">
                        <div className="avatar-title bg-light rounded">
                          <img
                            src={imageSet[index % 4]}
                            alt=""
                            className="avatar-xxs"
                          />
                        </div>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-icon btn-soft-primary float-end"
                        data-bs-toggle="button"
                        aria-pressed="true"
                      >
                        <i className="mdi mdi-cards-heart fs-16"></i>
                      </button> */}
                    </div>
                    <Link to="#">
                      <h5>{getTitle(key)}</h5>
                    </Link>
                    <p className="text-muted">{getDescription(key)}</p>
                    <div className="d-flex gap-4 mb-3">
                      {/* <div>
                        <i className="ri-map-pin-2-line text-primary me-1 align-bottom"></i>{" "}
                        Lorem Ipsum
                      </div>
                      <div>
                        <i className="ri-time-line text-primary me-1 align-bottom"></i>{" "}
                        Lorem Ipsum
                      </div> */}
                    </div>

                    {
                      <ul
                        className="list-group  mb-4"
                        style={{ overflow: "auto" }}
                      >
                        <>
                          {understanding &&
                            getUnderstandingProperty(key)?.map(
                              (sentance: string, index: number) => (
                                <li
                                  className="list-group-item"
                                  key={index + 1}
                                  style={{ color: "gray" }}
                                >
                                  {/* <i className=" ri-checkbox-blank-circle-fill me-3" style={{fontSize: 8}}></i> */}
                                  {sentance}
                                </li>
                              )
                            )}
                        </>
                      </ul>
                    }
                    <div className="mt-auto mb-4 gap-2">
                      {cardData[key].tags.map((subItem: any, key: any) => (
                        <React.Fragment key={key}>
                          {key === 0 ? (
                            <span className="badge bg-success-subtle text-success">
                              {subItem}
                            </span>
                          ) : key === 1 ? (
                            <span className="badge bg-primary-subtle text-primary">
                              {subItem}
                            </span>
                          ) : (
                            <span className="badge bg-danger-subtle  text-danger">
                              {subItem}
                            </span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="hstack gap-2">
                      <Button onClick={() => {
                        handleEdit(key);
                      }}
                        color="primary"
                        outline
                        className="btn-border w-100">Edit
                      </Button>
                      <Button onClick={() => {
                        openDeleteModal(key);
                      }}
                        color="danger"
                        outline
                        className="btn-border w-100"> Delete
                      </Button>


                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
      </Row>

      <Modal
        isOpen={modalGrid}
        toggle={() => setModalGrid(!modalGrid)}
        centered={true}
      >
        <ModalHeader toggle={() => setModalGrid(!modalGrid)}>
          <h5 className="modal-title d-flex justify-content-end">
            Edit Understanding
          </h5>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateCardData();
            }}
          >
            <div className="row g-3">
              {/* <div>
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  placeholder="Enter Title.."
                  onChange={(e) => setTitle(getTitle(editCardId))}
                  disabled
                />
              </div> */}

              {/* <div>
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <Input
                  type="textarea"
                  rows="3"
                  className="form-control"
                  name="description"
                  id="description"
                  value={description}
                  onChange={(e) => handleList(e.target.value)}
                  placeholder="Enter Description"
                />
              </div> */}

              <label htmlFor="description" className="form-label">
                {getTitle(editCardId)}
              </label>
              <ul className="list-group  mb-4" style={{ overflow: "auto" }}>
                <>
                  {description.map((sentance: string, index: number) => (
                    <>
                      <div className="d-flex justify-content-between">
                        <Input
                          type="text"
                          className="form-control mb-2 mx-2"
                          name="description"
                          id="description"
                          onChange={(e) => handleList(e.target.value, index)}
                          value={sentance}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-icon waves-effect waves-light"
                          onClick={() => { removeList(index) }}
                        >
                          <i className="ri-delete-bin-5-line"></i>
                        </button>

                      </div>
                      {/* <Row>
                        <Col>
                          <Input
                            type="text"
                            className="form-control mb-2"
                            name="description"
                            id="description"
                            onChange={(e) => handleList(e.target.value, index)}
                            value={sentance}
                          />
                        </Col>
                        <Col lg={2}>
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-icon waves-effect waves-light"
                          >
                            <i className="ri-delete-bin-5-line"></i>
                          </button>
                        </Col>
                      </Row> */}
                    </>
                  ))}
                </>
              </ul>
              <Row>
                <Col>
                  <Button onClick={addToList}>
                    <i className="ri-add-circle-line"></i>Add
                  </Button>
                </Col>
              </Row>

              {/* <div>
                <label htmlFor="tags" className="form-label">
                  Tag Name
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="tags"
                  value={tags}
                  onChange={(e) => setTags(cardData[editCardId].tags.join(","))}
                  placeholder="Enter Your Tag Title"
                  disabled
                />
              </div> */}
              <Button color="primary" type="submit">
                Update
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={deleteModalGrid}
        toggle={() => setDeleteModalGrid(!deleteModalGrid)}
        centered={true}
      >
        <ModalHeader toggle={() => setDeleteModalGrid(!deleteModalGrid)}>
          <h5 className="modal-title d-flex justify-content-end">
            Delete
          </h5>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDelete()
              console.log("submitted")
            }}
          >
            <div className="row g-3">
              <p>Are you sure you want to delete this card?</p>
              <Button color="primary" type="submit" >
                Delete
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Understanding;
