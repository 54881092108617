import React, {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import rough from "roughjs";
import { useHistory } from "../../hooks/useHistory";
import { usePressedKeys } from "../../hooks/usePressedKeys";
import {
  ToolsType,
  SelectedElementType,
  ExtendedElementType,
  ElementType,
  Tools,
  ActionsType,
  PointType,
} from "../../types";
import { ActionBar } from "../../component/action-bar/action-bar";
import { ControlPanel } from "../../component/control-panel/control-panel";
import { Info } from "../../component/info/info";
import {
  adjustElementCoordinates,
  adjustmentRequired,
  createElement,
  cursorForPosition,
  drawElement,
  getElementAtPosition,
  resizedCoordinates,
} from "../../utilities";
import { Button, Card, CardBody, Container } from "reactstrap";
// import { LuEraser, LuPencil } from "react-icons/lu";
import ImageDropper from "./ImageDropper";

import { useEditable } from "./Contaxt";
import IframePreview from "./PreviewIFrame";

// Save image screenshot inside useEditable

export default function AppDraw() {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const initialTool: ToolsType = Tools.selection;
  const { elements, setElements, undo, redo } = useHistory([]);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [startPanMousePosition, setStartPanMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [action, setAction] = useState<ActionsType>("none");
  const [tool, setTool] = useState<ToolsType>(initialTool);
  const [selectedElement, setSelectedElement] = useState<ElementType | null>();
  const [scale, setScale] = useState(1);
  const [scaleOffset, setScaleOffset] = useState({ x: 0, y: 0 });
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const pressedKeys = usePressedKeys();
  const [selectedElements, setSelectedElements] = useState<ElementType[]>([]);
  const [multiSelectedElements, setMultiSelectedElements] = useState<
    ElementType[]
  >([]);
  const [multiSelectAction, setMultiSelectAction] = useState<ActionsType>("");
  const [
    startMultiSelectPanMousePosition,
    setStartMultiSelectPanMousePosition,
  ] = useState<PointType>({ x: 0, y: 0 });
  const [uploadedImage, setUploadedImage] = useState<HTMLImageElement | null>(
    null
  );
  const [uploadedImagePosition, setUploadedImagePosition] = useState<PointType>(
    { x: 50, y: 0 }
  );
  const [uploadedImageSize, setUploadedImageSize] = useState({
    width: 800,
    height: 550,
  });
  // const pressedKeys = usePressedKeys();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctx = useRef<CanvasRenderingContext2D | null>(null);
  const [showComponent, setShowComponent] = useState<string>("");

  const {
    imgData,
    handleScreenshot,
    handleBackClickPreview,
    handleDownloadImage,
    handleSaveEditedScreenshot,
  } = useEditable();

  const [editingImage, setEditingImage] = useState<HTMLImageElement | null>(
    null
  );
  // const [isResizingImage, setIsResizingImage] = useState(false);

  useLayoutEffect(() => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    const roughCanvas = rough.canvas(canvas);

    context.clearRect(0, 0, canvas.width, canvas.height);
    const scaledWidth = canvas.width * scale;
    const scaledHeight = canvas.height * scale;
    const scaleOffsetX = (scaledWidth - canvas.width) / 2;
    const scaleOffsetY = (scaledHeight - canvas.height) / 2;
    setScaleOffset({ x: scaleOffsetX, y: scaleOffsetY });

    context.save();
    context.translate(
      panOffset.x * scale - scaleOffsetX,
      panOffset.y * scale - scaleOffsetY
    );
    context.scale(scale, scale);

    const _canvas = canvasRef.current;
    if (!_canvas) return;
    ctx.current = _canvas.getContext("2d");
    if (!ctx.current) return;
    ctx.current.clearRect(0, 0, canvas.width, canvas.height);

    if (selectedImage && !editingImage) {
    console.log("Screenshot API Image Data in Appdraw ii", selectedImage);

      const img = new Image();
      img.src = selectedImage;
      img.onload = () => {
        ctx.current = context;
        ctx.current.drawImage(
          img,
          canvas.width / 2 - img.width / 2,
          canvas.height / 2 - img.height / 2,
          img.width,
          img.height
        );
        setEditingImage(img);
      };
    } else if (editingImage) {
      ctx.current.drawImage(
        editingImage,
        canvas.width / 2 - editingImage.width / 2,
        canvas.height / 2 - editingImage.height / 2,
        editingImage.width,
        editingImage.height
      );
    }

    if (uploadedImage) {
      console.log("Image uploaded");
      const { x, y } = uploadedImagePosition;
      const { width, height } = uploadedImageSize;
      context.drawImage(uploadedImage, x, y, width, height);
    }

    // Draw the elements on top of the screenshot or uploaded image
    elements.forEach((element) => {
      if (action === "writing" && selectedElement) return;
      drawElement(roughCanvas, context, element);
    });

    context.restore();
  }, [
    elements,
    selectedImage,
    action,
    selectedElement,
    panOffset,
    scale,
    tool,
    uploadedImage,
    uploadedImagePosition,
    uploadedImageSize,
  ]);

  useEffect(() => {
    setSelectedImage(imgData);
  }, [selectedImage, handleScreenshot]);

  const handleCursor = () => {
    switch (tool) {
      // case Tools.pan:
      //   return "grabbing";
      case Tools.selection:
        return "move";
      case Tools.rectangle:
        return "crosshair";
      case Tools.line:
        return "crosshair";
      case Tools.pencil:
        return "crosshair";
      case Tools.erease:
        return "crosshair";
      case Tools.text:
        return "text";
      default:
        return "default";
    }
  };

  useEffect(() => {
    const undoRedoFunction = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "z") {
          if (event.shiftKey) {
            redo();
          } else {
            undo();
          }
        } else if (event.key === "y") {
          redo();
        }
      }
    };

    document.addEventListener("keydown", undoRedoFunction);
    return () => {
      document.removeEventListener("keydown", undoRedoFunction);
    };
  }, [undo, redo]);

  useEffect(() => {
    const textArea = textAreaRef.current;
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    canvas.style.cursor = handleCursor();
    if (action === "writing" && textArea && selectedElement) {
      setTimeout(() => {
        textArea.focus();
        textArea.value = selectedElement.text || "";
      }, 0);
    }
  }, [action, selectedElement, tool]);

  const updateElement = (
    id: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    type: ToolsType,
    options?: { text: string }
  ) => {
    const elementsCopy = [...elements];
    switch (type) {
      case Tools.line:
      case Tools.importImage:
      case Tools.rectangle: {
        elementsCopy[id] = createElement(id, x1, y1, x2, y2, type);
        break;
      }
      case Tools.pencil: {
        const existingPoints = elementsCopy[id].points || [];
        elementsCopy[id].points = [...existingPoints, { x: x2, y: y2 }];
        break;
      }
      case Tools.text: {
        const canvas = document.getElementById("canvas");
        if (!(canvas instanceof HTMLCanvasElement)) {
          throw new Error("Canvas element not found");
        }
        const context = canvas.getContext("2d");
        if (!context) {
          throw new Error("Could not get 2D context from canvas");
        }
        if (!options) {
          throw new Error("No text options provided for text tool");
        }
        const textWidth = context.measureText(options.text).width;
        const textHeight = 5;
        elementsCopy[id] = {
          ...createElement(id, x1, y1, x1 + textWidth, y1 + textHeight, type),
          text: options.text,
        };
        break;
      }
      default:
        throw new Error(`Type not recognised: ${type}`);
    }
    setElements(elementsCopy, true);
  };

  const getMouseCoordinates = (event: MouseEvent<HTMLCanvasElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const scaleX = event.currentTarget.width / rect.width;
    const scaleY = event.currentTarget.height / rect.height;
    const clientX = (event.clientX - rect.left) * scaleX - panOffset.x;
    const clientY = (event.clientY - rect.top) * scaleY - panOffset.y;
    return { clientX, clientY };
  };

  //Handle erease Functionality
  const handleEraseElement = (x: number, y: number) => {
    // Find the element at the clicked coordinates
    const element = getElementAtPosition(x, y, elements);
    if (element) {
      // Remove the element from the elements array
      const updatedElements = elements.filter((el) => el.id !== element.id);
      setElements(updatedElements);
    }
  };
  const handleMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    if (action === "writing") return;

    const { clientX, clientY } = getMouseCoordinates(event);

    if (tool === Tools.erease) {
      handleEraseElement(clientX, clientY);
      return;
    }

    if (uploadedImage) {
      const { x, y } = uploadedImagePosition;
      const { width, height } = uploadedImageSize;

      if (
        clientX >= x &&
        clientX <= x + width &&
        clientY >= y &&
        clientY <= y + height
      ) {
        // Check if the click is near the bottom-right corner for resizing
      }
    }

    if (tool === Tools.selection) {
      const element = getElementAtPosition(clientX, clientY, elements);
      if (element) {
        let selectedElement: SelectedElementType = { ...element };

        if (element.type === "pencil" && element.points) {
          const xOffsets = element.points.map((point) => clientX - point.x);
          const yOffsets = element.points.map((point) => clientY - point.y);
          selectedElement = { ...selectedElement, xOffsets, yOffsets };
        } else {
          const offsetX = clientX - selectedElement.x1;
          const offsetY = clientY - selectedElement.y1;
          selectedElement = { ...selectedElement, offsetX, offsetY };
        }

        setSelectedElement(selectedElement);
        setElements((prevState) => prevState);

        if (element.position === "inside") {
          setAction("moving");
        } else {
          setAction("resizing");
        }
      }
    } else {
      const id = elements.length;
      const newElement = createElement(
        id,
        clientX,
        clientY,
        clientX,
        clientY,
        tool
      );
      setElements((prevState) => [...prevState, newElement]);
      setSelectedElement(newElement);
      setAction(tool === "text" ? "writing" : "drawing");
    }
  };

  const handleMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = getMouseCoordinates(event);
   
    if (action === "panning") {
      const deltaX = clientX - startPanMousePosition.x;
      const deltaY = clientY - startPanMousePosition.y;
      setPanOffset({
        x: panOffset.x + deltaX,
        y: panOffset.y + deltaY,
      });
      return;
    }

    if (multiSelectAction === "panning") {
      const deltaX = clientX - startMultiSelectPanMousePosition.x;
      const deltaY = clientY - startMultiSelectPanMousePosition.y;
      setPanOffset({
        x: panOffset.x + deltaX,
        y: panOffset.y + deltaY,
      });
      return;
    }

    if (
      multiSelectAction === "multi_moving" &&
      multiSelectedElements.length > 0
    ) {
      const deltaX = clientX - startMultiSelectPanMousePosition.x;
      const deltaY = clientY - startMultiSelectPanMousePosition.y;
      const updatedElements = elements.map((element) => {
        if (
          multiSelectedElements.some((selected) => selected.id === element.id)
        ) {
          return {
            ...element,
            x1: element.x1 + deltaX,
            x2: element.x2 + deltaX,
            y1: element.y1 + deltaY,
            y2: element.y2 + deltaY,
          };
        }
        return element;
      });
      setElements(updatedElements);
      setStartMultiSelectPanMousePosition({ x: clientX, y: clientY });
      return;
    }

    if (tool === Tools.selection) {
      const element = getElementAtPosition(clientX, clientY, elements);

      if (element && element.position) {
        (event.target as HTMLElement).style.cursor = cursorForPosition(
          element.position
        );
      } else {
        (event.target as HTMLElement).style.cursor = "default";
      }
    }

    if (action === "moving" && selectedElements.length > 0) {
      const deltaX = clientX - startPanMousePosition.x;
      const deltaY = clientY - startPanMousePosition.y;
      const updatedElements = elements.map((element) => {
        if (selectedElements.some((selected) => selected.id === element.id)) {
          return {
            ...element,
            x1: element.x1 + deltaX,
            x2: element.x2 + deltaX,
            y1: element.y1 + deltaY,
            y2: element.y2 + deltaY,
          };
        }
        return element;
      });
      setElements(updatedElements);
      setStartPanMousePosition({ x: clientX, y: clientY });
      return;
    }

    if (action === "drawing") {
      const index = elements.length - 1;
      const { x1, y1 } = elements[index];
      updateElement(index, x1, y1, clientX, clientY, tool);
    } else if (action === "moving" && selectedElement) {
      if (
        selectedElement.type === "pencil" &&
        "points" in selectedElement &&
        "xOffsets" in selectedElement &&
        "yOffsets" in selectedElement
      ) {
        const extendedElement = selectedElement as ExtendedElementType;
        const newPoints = extendedElement.points!.map((_, index) => ({
          x: clientX - extendedElement.xOffsets![index],
          y: clientY - extendedElement.yOffsets![index],
        }));
        const elementsCopy = [...elements];
        elementsCopy[extendedElement.id] = {
          ...elementsCopy[extendedElement.id],
          points: newPoints,
        };
        setElements(elementsCopy, true);
      } else {
        const { id, x1, x2, y1, y2, type, offsetX, offsetY } =
          selectedElement as ExtendedElementType;
        const safeOffsetX = offsetX ?? 0;
        const safeOffsetY = offsetY ?? 0;
        const newX1 = clientX - safeOffsetX;
        const newY1 = clientY - safeOffsetY;
        const newX2 = newX1 + (x2 - x1);
        const newY2 = newY1 + (y2 - y1);
        const options =
          type === "text" && selectedElement.text
            ? { text: selectedElement.text }
            : undefined;
        updateElement(id, newX1, newY1, newX2, newY2, type, options);
      }
    } else if (
      action === "resizing" &&
      selectedElement &&
      selectedElement.position
    ) {
      const { id, type, position, ...coordinates } =
        selectedElement as ExtendedElementType;

      if (typeof position === "string") {
        const { x1, y1, x2, y2 } = resizedCoordinates(
          clientX,
          clientY,
          position,
          coordinates
        );
        updateElement(id, x1, y1, x2, y2, type);
      }
    }
  };
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {

      const img = new Image();
      console.log("Datta from Image", img);
      img.onload = () => {
        const canvas = document.getElementById("canvas") as HTMLCanvasElement;
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        let width = img.width;
        let height = img.height;

        // Scale the image to fit within the canvas dimensions
        if (width > canvasWidth || height > canvasHeight) {
          const widthRatio = canvasWidth / width;
          const heightRatio = canvasHeight / height;
          const minRatio = Math.min(widthRatio, heightRatio);

          width *= minRatio;
          height *= minRatio;
        }

        setUploadedImage(img);
        setUploadedImageSize({ width, height });
        setUploadedImagePosition({
          x: (canvasWidth - width) / 2,
          y: (canvasHeight - height) / 2,
        });
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleMouseUp = (event: MouseEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = getMouseCoordinates(event);

    if (selectedElement) {
      const index = selectedElement.id;
      const { id, type } = elements[index];
      if (
        (action === "drawing" || action === "resizing") &&
        adjustmentRequired(type)
      ) {
        const { x1, y1, x2, y2 } = adjustElementCoordinates(elements[index]);
        updateElement(id, x1, y1, x2, y2, type);
      }

      const offsetX = selectedElement.offsetX || 0;
      const offsetY = selectedElement.offsetY || 0;

      if (
        selectedElement.type === "text" &&
        clientX - offsetX === selectedElement.x1 &&
        clientY - offsetY === selectedElement.y1
      ) {
        setAction("writing");
        return;
      }
    }

    if (action === "writing") {
      return;
    }

    if (action === "panning") {
      document.body.style.cursor = "pen";
    }
    if (multiSelectAction === "multi_moving") {
      setMultiSelectAction("");
    }
    document.body.style.cursor = "default";

    setAction("none");
    setSelectedElement(null);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (selectedElement) {
      const { id, x1, y1, type } = selectedElement;

      const x2 = selectedElement.x2 || x1;
      const y2 = selectedElement.y2 || y1;

      setAction("none");
      setSelectedElement(null);
      updateElement(id, x1, y1, x2, y2, type, { text: event.target.value });
    } else {
      console.error("No element selected when handleBlur was called");
    }
  };

  const onZoom = (delta: number) => {
    setScale((prevState) => Math.min(Math.max(prevState + delta, 0.1), 20));
  };

  const handleZoom = (event: React.WheelEvent<HTMLCanvasElement>) => {
    event.preventDefault();
    const { deltaY } = event;
    const newScale = scale - deltaY * 0.01;
    setScale(Math.min(Math.max(0.1, newScale), 1));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <Info />
            <ActionBar
              tool={tool}
              setTool={setTool}
              handleImageUpload={handleImageUpload}
            />
            <CardBody>
              <ControlPanel
                undo={undo}
                redo={redo}
                onZoom={onZoom}
                scale={scale}
                setScale={setScale}
              />
              {action === "writing" ? (
                <textarea
                  ref={textAreaRef}
                  onBlur={handleBlur}
                  className="textArea"
                  style={{ font: `${24 * scale}px sans-serif` }}
                />
              ) : null}
              <i
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "25px",
                  padding: "7px 11px",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
                className="ri-arrow-left-line rounded-pill shadow-lg back-btn"
                onClick={handleBackClickPreview}
              ></i>

              <canvas
                id="canvas"
                ref={canvasRef}
                width={window.innerWidth}
                height={window.innerHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                // onWheel={handleZoom}
                style={{ border: "1px solid #ccc", height: "80vh" }}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
