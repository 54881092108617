import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import "./input-field.css";
import { useEditable } from "./Contaxt";
import ImageToCodeContainer from "./ImageToCodeContainer";
import API_Error from "./Error/API-Error-page";

const ImageTOApp = () => {
  const {
    inputValue,
    handleChange,
    textareaRef,
    loading,
    handleGenerateCodeForEditedImage,
    currentComponent,
    showAppComponent,
    showAppDrawComponent,
    error,
    isSendButtonDisabled,
    setIsSendButtonDisabled,
    handleButtonClick
  } = useEditable();


  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleButtonClick();
    }
  };

  // const isSendButtonEnabled = inputValue && selectedFiles.length > 0;

  // if(inputValue && selectedFiles.length > 0){
  //   setIsSendButtonDisabled(true)
  // }
  if(inputValue){
    setIsSendButtonDisabled(true)
  }
 

  return (
    <React.Fragment>
      {error ? (
        <API_Error />
      ) : (
        <>
          <ImageToCodeContainer />
          {loading
            ? " "
            : showAppComponent !== "priviewIframe" && (
                <Card className="container-fluid input-card">
                  <CardBody>
                    <div className="d-flex justify-content-end align-items-center chat-input-container">
                      <textarea
                        ref={textareaRef}
                        className="chat-input chat-input bg-light border-light"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder={inputValue}
                      />
                      <Button
                        className={`send-button ${inputValue ? "enabled" : ""}`}
                        disabled={!inputValue}
                        onClick={handleButtonClick}
                      >
                        <i className="text-white ri-send-plane-2-fill"></i>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}
        </>
      )}
    </React.Fragment>
  );
};

export default ImageTOApp;
