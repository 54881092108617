import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./PreviewFrame.css"; 
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from "reactstrap";
import { useEditable } from "./Contaxt";

const ImageDropper: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const location = useLocation();
  let { state } = location;
  const {
    handleNextClickPreview,
    handleAcceptedFiles,
    deleteImage,
    selectedFiles,
    isNextButtonEnabled,
    setShowAppComponent,
    setApiResponse,
    setImageURI
  } = useEditable();
  const navigate = useNavigate();
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleModalWithImage = (imagePreview: string | null) => {
    if (imagePreview) {
      // console.log("imagepreview:", imagePreview)
      setModalImage(imagePreview);
    }
    toggleModal();
  };

  useEffect(()=> {
    if(state) {
      setShowAppComponent("priviewIframe")
      setApiResponse(state.code)
      setImageURI("data:image/png;base64,"+ state.design)
      navigate(location.pathname, { replace: true });
    }

  },[navigate, location.pathname, setApiResponse, setShowAppComponent, state, setImageURI])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card style={{ height: "73.5vh" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="text-warning fs-1 rounded">
                    <i className="ri-information-line"></i>
                  </div>
                  <p className="text-muted mb-0" style={{ marginLeft: "10px" }}>
                    You can upload your pencil sketch or your Figma design
                  </p>
                </div>
                {/* <Button
                  color="primary"
                  onClick={handleNextClickPreview}
                >
                  Next
                </Button> */}
                <i
                  className="px-2 py-2  ri-arrow-right-line rounded-pill shadow-lg back-btn"
                  onClick={handleNextClickPreview}
                  style={{ cursor: "pointer", fontSize: "20px" }}
                ></i>
              </div>
              <CardBody>
                <Dropzone onDrop={handleAcceptedFiles}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="list-unstyled mb-0" id="file-previews">
                  {selectedFiles.map((f: any, index: number) => (
                    <Card
                      className="mt-1 mb-3 shadow-none border dz-image-preview"
                      key={index + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                              onClick={() => toggleModalWithImage(f.preview)}
                              style={{ cursor: "pointer" }}
                            />
                          </Col>
                          <Col
                            onClick={() => toggleModalWithImage(f.preview)}
                            style={{ cursor: "pointer" }}
                          >
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          <Col className="ml-auto">
                            <button
                              className="btn btn-danger btn-sm mt-2"
                              onClick={() => deleteImage(index)}
                            >
                              Delete
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {/* Modal for Image Preview */}
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal as any}>Image Preview</ModalHeader>
        <ModalBody>
          {modalImage && (
            <img
              src={modalImage}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ImageDropper;
