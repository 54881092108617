import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
  ChangeEvent,
} from "react";
import { axiosInstance } from "Mike/utils/axiosConfig";
import axios, { Canceler } from "axios";


interface EditableContextType {
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  handleScreenshot: () => Promise<void>;
  imgData: string | null;
  showAppComponent: string;
  setShowAppComponent: React.Dispatch<React.SetStateAction<string>>;
  handleNextClickPreview: () => void;
  handleBackClickPreview: () => void;
  handleNextClickCanvas: () => void;
  handleBackClickImgDropper: () => void;
  showAppDrawComponent: string;

  handleAcceptedFiles: (files: any[]) => void;
  deleteImage: (index: number) => void;
  selectedFiles: any[];
  isNextButtonEnabled: boolean;
  uploadedImage: string | null;
  imageURI: string | null;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleGenerateCode: () => Promise<void>;
  inputValue: string;
  apiResponse: string;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  loading: boolean;
  handleDownloadImage: () => void;
  dataURIEdited: string | null;
  handleSaveEditedScreenshot: () => void;
  handleGenerateCodeForEditedImage: () => Promise<void>;
  currentComponent: string;
  setCurrentComponent: React.Dispatch<React.SetStateAction<string>>;
  // handleGenerateAPIForPreview: () => Promise<void>;
  history: string[];
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  selectedButton: string;
  setSelectedButton: React.Dispatch<React.SetStateAction<string>>;
  setApiResponse: React.Dispatch<React.SetStateAction<string>>;
  handleEditScreenshot: () => void;
  isEditButtonDisabled: boolean;
  setScreenshotsData: React.Dispatch<React.SetStateAction<any[]>>;
  setIsSendButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isSendButtonDisabled: boolean;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  handleDeleteCard: (index: number) => void
  handleButtonClick: () => void;
  cancelGetScreenshot: (() => void) | null;
  setCancelGetScreenshot: React.Dispatch<React.SetStateAction<(() => void) | null>>;
  setImageURI: React.Dispatch<React.SetStateAction<string | null>>

}

const EditableContext = createContext<EditableContextType | undefined>(
  undefined
);

export const useEditable = (): EditableContextType => {
  const context = useContext(EditableContext);
  if (!context) {
    throw new Error("useEditable must be used within an EditableProvider");
  }
  return context;
};

export const EditableProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [imgData, setImgData] = useState<string | null>(null);
  const [showAppComponent, setShowAppComponent] =
    useState<string>("ImageDropper");
  const [showAppDrawComponent, setShowAppDrawComponent] = useState<string>("");

  //State for Image Dropper
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [imageURI, setImageURI] = useState<string | null>(null);

  const [inputValue, setInputValue] = useState<string>(
    "Convert the given image into code"
  );
  const [apiResponse, setApiResponse] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState("computer");
  //state for AppDraw Component
  const [dataURIEdited, setDataURIEdited] = useState<string | null>(null);
  // In your context or parent component
  const [history, setHistory] = useState<string[]>([]);

  const [currentComponent, setCurrentComponent] =
    useState<string>("ImageDropper");
  const source = axios.CancelToken.source();
  // Handling Error page
  const [error, setError] = useState<string | null>(null);
  const [screenshotsData, setScreenshotsData] = useState<any[]>([]);
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  // let screenshotsData: any[] = [];
  const [cancelGetScreenshot, setCancelGetScreenshot] = useState<(() => void) | null>(null);

  console.log("Screnshot Data for three devices", screenshotsData);

  const handleScreenshot = async () => {
    console.log(
      "Automatically handle the API for Screenshot Data dependent on apiResponse"
    );

    if (cancelGetScreenshot) {
      cancelGetScreenshot();
    }
    const source = axios.CancelToken.source();
    setCancelGetScreenshot(() => source.cancel);

    const apiPayload = { html_content: apiResponse };
    // setError(null);

    try {
      const response = await axiosInstance.post("/get_screenshot", apiPayload, {
        cancelToken: source.token,
      });

      if (response.data.screenshots) {
        const { mobile, tablet, monitor } = response.data.screenshots;

        setScreenshotsData([
          { device: "mobile", data: mobile },
          { device: "tablet", data: tablet },
          { device: "monitor", data: monitor },
        ]);

        console.log("Updated Screenshot Data:", screenshotsData);
      } else {
        console.error("Error response data:", response.data);
        setError("Failed to get a valid response from the server.");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error in API call:", error.message);
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
          setError(
            `Server error: ${error.response.data.message || error.message}`
          );
        } else {
          if(error.message !== 'canceled')
            setError(`Network error: ${error.message}`);
        }
      } else {
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred.");
      }
    }
  };

  useEffect(() => {
    if (apiResponse) {
      handleScreenshot();
      // console.log("Array Of screenshot Data: ", screenshotsData);
    }
  }, [apiResponse]);

  // const handleEditScreenshot = () => {
  //   // Find the correct screenshot data based on the selected button
  //   let selectedImageData;
  //   // setModalContent({ title: "Exporting Code", desc: "Please Wait..." });
  //   setLoading(true);
  //   console.log("Selected button:", selectedButton);
  //   console.log("Screenshots data:", screenshotsData);

  //   if (selectedButton === "computer") {
  //     selectedImageData = screenshotsData.find(
  //       (item) => item.device === "monitor"
  //     );
  //   } else if (selectedButton === "smartphone") {
  //     selectedImageData = screenshotsData.find(
  //       (item) => item.device === "mobile"
  //     );
  //   } else if (selectedButton === "tablet") {
  //     selectedImageData = screenshotsData.find(
  //       (item) => item.device === "tablet"
  //     );
  //   }

  //   // Set the image data to be used in the canvas
  //   if (selectedImageData) {
  //     console.log("Selected image data:", selectedImageData);
  //     setImgData("data:image/jpeg;base64," + selectedImageData.data);
  //   } else {
  //     console.error("No screenshot data found for the selected device.");
  //   }
  //   setLoading(false);

  //   setShowAppComponent("AppDraw");
  // };

  useEffect(() => {
    const checkIfScreenshotDataExists = () => {
      let selectedImageData;

      if (selectedButton === "computer") {
        selectedImageData = screenshotsData.find(
          (item) => item.device === "monitor"
        );
      } else if (selectedButton === "smartphone") {
        selectedImageData = screenshotsData.find(
          (item) => item.device === "mobile"
        );
      } else if (selectedButton === "tablet") {
        selectedImageData = screenshotsData.find(
          (item) => item.device === "tablet"
        );
      }

      if (selectedImageData) {
        setIsEditButtonDisabled(false);
      } else {
        setIsEditButtonDisabled(true);
      }
    };

    checkIfScreenshotDataExists();
  }, [selectedButton, screenshotsData]);

  const handleEditScreenshot = () => {
    let selectedImageData;
    setLoading(true);
    console.log("Selected button:", selectedButton);
    console.log("Screenshots data:", screenshotsData);

    if (selectedButton === "computer") {
      selectedImageData = screenshotsData.find(
        (item) => item.device === "monitor"
      );
    } else if (selectedButton === "smartphone") {
      selectedImageData = screenshotsData.find(
        (item) => item.device === "mobile"
      );
    } else if (selectedButton === "tablet") {
      selectedImageData = screenshotsData.find(
        (item) => item.device === "tablet"
      );
    }

    if (selectedImageData) {
      console.log("Selected image data:", selectedImageData);
      setImgData("data:image/jpeg;base64," + selectedImageData.data);
    } else {
      console.error("No screenshot data found for the selected device.");
    }
    setLoading(false);
    setShowAppComponent("AppDraw");
  };

  const handleNextClickPreview = () => {
    setShowAppComponent("priviewIframe"); // Set state to show AppDraw
  };
  const handleNextClickCanvas = () => {
    setShowAppComponent("AppDraw"); // Set state to show AppDraw
    setInputValue("")
  };
  const handleBackClickPreview = () => {
    setShowAppComponent("priviewIframe"); // Set state to show ImageDropper
  };
  const handleBackClickImgDropper = () => {
    setShowAppComponent("ImageDropper"); // Set state to show ImageDropper
    setIsSendButtonDisabled(false)
    setInputValue("Convert the given image into code")
  };

  // Image Dropper Function
  const handleAcceptedFiles = (files: any[]) => {
    if (files.length > 0) {
      setIsNextButtonEnabled(true);
    }

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageURI = reader.result as string;
        // console.log("image uri", imageURI)
        const newFile = {
          name: file.name,
          preview: URL.createObjectURL(file),
          byteURI: [imageURI],
          formattedSize: formatBytes(file.size),
        };

        setSelectedFiles((prevFiles) => [...prevFiles, newFile]);

        // Example: Setting uploaded image state for preview
        setUploadedImage(newFile.preview);
        setImageURI(imageURI);

        // Call API to upload the image
        // uploadImageToAPI(newFile);
      };

      reader.readAsDataURL(file);
    });
  };

  //Send button to call API

  const handleGenerateCode = async () => {
    setScreenshotsData([]);
    if (!inputValue && !imageURI) return;
    console.log("Handle Click ImageDropper and Preview Working");
    const apiPayload = {
      mode: "MLO_I2C_HTML",
      prompt: inputValue,
      image: [imageURI], // Ensure imageURI is a valid base64 string
    };
    setLoading(true);
    setError(null); // Reset error state before making the API call

    try {
      const response = await axiosInstance.post("/image_to_code", apiPayload);
      if (response.data.status_code === 200) {
        const newCode = response.data.code;
        // setHistory((prevHistory) => [...prevHistory, newCode]);
        setApiResponse(newCode);
        // console.log("Response From Server:", newCode);
      } else {
        console.log("There seems to be some error");
        console.error("Error response data:", response.data);
        setError(null);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error in API call:", error.message);
        // Accessing and logging error details from Axios response
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
          setError(
            `Server error: ${error.response.data.message || error.message}`
          );
        } else {
          setError(`Network error: ${error.message}`);
        }
      } else {
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }

    setInputValue("");
    setSelectedFiles([]);
    handleNextClickPreview();
    // setIsSendButtonDisabled(false);
  };

  
  const handleButtonClick = () => {
    if (showAppComponent === "ImageDropper") {
      handleGenerateCode();
    } else if (showAppComponent === "priviewIframe") {
      console.log(" calling priviewIframe");
    } else if (showAppComponent === "AppDraw") {
      console.log(" calling AppDraw");
      handleGenerateCodeForEditedImage();
    }
  };

  // Prompt Text Input field
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "52px";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        160
      )}px`;
    }
  }, [inputValue]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  // delete Image
  const deleteImage = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      setIsNextButtonEnabled(false);
    }
  };

  // function from App Draw for downlode code
  const handleDownloadImage = () => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const context = canvas.getContext("2d");

    if (!context) {
      console.error("Canvas context is not available");
      return;
    }

    // Check if the canvas is empty
    const canvasData = context.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    ).data;
    let isEmpty = true;

    for (let i = 0; i < canvasData.length; i += 4) {
      if (canvasData[i + 3] !== 0) {
        isEmpty = false;
        break;
      }
    }

    if (isEmpty) {
      alert("Nothing to Download");
      return;
    }

    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "canvas-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // handle Save Edited Screenshot
  const handleSaveEditedScreenshot = () => {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const dataURL = canvas.toDataURL();
    // console.log(dataURL);
    // setDataURIEdited(dataURL);
    alert("Image Save Successfully");
  };

  const handleGenerateCodeForEditedImage = async () => {
    setScreenshotsData([]);
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const dataURL = canvas.toDataURL();
    if (!inputValue && !dataURL) return;
    console.log("Handle Click ImageDropper and Preview Working");
    const apiPayload = {
      mode: "MLO_I2C_HTML",
      prompt: `${inputValue}. Here is the previous html generated code for the reference, modify this html as required ${apiResponse}. Only return the HTML inside the \`\`\`html markdown.`,

      image: [imageURI, dataURL], // Ensure imageURI is a valid base64 string
    };
    setLoading(true);
    setError(null); // Reset error state before making the API call

    try {
      const response = await axiosInstance.post("/image_to_code", apiPayload);
      if (response.data.status_code === 200) {
        const newCode = response.data.code;
        setHistory((prevHistory) => [...prevHistory, newCode]);
        setApiResponse(newCode);
        // console.log("Response From Server:", newCode);
      } else {
        console.log("There seems to be some error");
        console.error("Error response data:", response.data);
        setError("Failed to get a valid response from the server.");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error in API call:", error.message);
        // Accessing and logging error details from Axios response
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
          setError(
            `Server error: ${error.response.data.message || error.message}`
          );
        } else {
          setError(`Network error: ${error.message}`);
        }
      } else {
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }

    setInputValue("");
    setSelectedFiles([]);
    handleNextClickPreview();
  };
  
  useEffect(() => {
    if (apiResponse) {
      setHistory((prevHistory) => [...prevHistory, apiResponse]);
    }
  }, [apiResponse]);

  //Handle delete History card
  const handleDeleteCard = (index: number) => {
    setHistory((prevHistory) => prevHistory.filter((_, i) => i !== index));
  };

  return (
    <EditableContext.Provider
      value={{
        isEditable,
        setIsEditable,
        handleScreenshot,
        imgData,
        showAppComponent,
        setShowAppComponent,
        handleNextClickPreview,
        handleBackClickPreview,
        handleNextClickCanvas,
        handleBackClickImgDropper,
        showAppDrawComponent,
        handleAcceptedFiles,
        deleteImage,
        selectedFiles,
        isNextButtonEnabled,
        uploadedImage,
        imageURI,
        apiResponse,
        handleGenerateCode,
        inputValue,
        textareaRef,
        handleChange,
        loading,
        handleDownloadImage,
        dataURIEdited,
        handleSaveEditedScreenshot,
        handleGenerateCodeForEditedImage,
        currentComponent,
        setCurrentComponent,
        // handleGenerateAPIForPreview,
        history,
        setHistory,
        error,
        setError,
        selectedButton,
        setSelectedButton,
        setApiResponse,
        handleEditScreenshot,
        isEditButtonDisabled,
        setScreenshotsData,
        setIsSendButtonDisabled,
        isSendButtonDisabled,
        handleDeleteCard,
        handleButtonClick,
        setCancelGetScreenshot,
        cancelGetScreenshot,
        setImageURI
      }}
    >
      {children}
    </EditableContext.Provider>
  );
};
