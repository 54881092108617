import React from 'react'

const checkREact = () => {
  return (
    <div>
      
    </div>
  )
}

export default checkREact
