import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  UncontrolledDropdown,
  Progress,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDirectContact as onGetDirectContact,
  getMessages,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage,
} from "../../slices/thunks";
import mlo from "../../assets/images/mikeLogo.png";
import dashboard_a from "../../assets/images/file.png";
import dashboard_b from "../../assets/images/file.png";
import dashboard from "../../assets/images/file.png";
import userDummayImage from "../../assets/images/users/avatar-1.jpg";
import "react-perfect-scrollbar/dist/css/styles.css";
import { createSelector } from "reselect";
import GrapesJS from "./GrapesJs";
import axios from "axios";


type UserMessage = {
  id: number;
  from_id: number;
  to_id: number;
  msg: string | null;
  reply: { sender: string; msg: string; id: number };
  isImages: boolean;
  has_images: { id: number; image: string }[];
  datetime: string;
};

type userMessagesType = {
  id: number;
  roomId: number;
  sender: string;
  createdAt: string;
  usermessages: UserMessage[];
};

const GenerateWireFrame = () => {
  const userChatShow: any = useRef();
  const dispatch = useDispatch<any>();
  const [Chat_Box_Username, setChat_Box_Username] =
    useState<any>("Michelangelo");
  const [user_Status, setUser_Status] = useState<string | null>(
    "Wireframe Creator"
  );
  const [Chat_Box_Image, setChat_Box_Image] = useState<any>(mlo);
  const [currentRoomId, setCurrentRoomId] = useState<any>(1);
  const [curMessage, setcurMessage] = useState<string>("");
  const [reply, setreply] = useState<any>("");
  const [isLoading, setLoading] = useState(false);
  const [showGrapesPage, setShowGrapesPage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState<any>(null);
  const [messages, setMessages] = useState([
    "Generating your design...",
    "Finding the right components...",
    "Have a cup of coffe while we finalise the design...",
    "Finalizing...",
  ]);
  const [view, setView] = useState(false)
  const navigate = useNavigate();

  const [chatMessages, setChatMessages] = useState<any>([
    {
      id: 1,
      roomId: 1,
      sender: "1",
      createdAt: "",
      usermessages: [],
    },
  ]);
  const mock_design = [dashboard_a, dashboard_b, dashboard];
  

  const selectLayoutState = (state: any) => state.Chat;
  const chatProperties = createSelector(selectLayoutState, (state: any) => ({
    chats: state.chats,
    messages: state.messages,
    loading: state.loading,
  }));

  const [imageId, setImageId] = useState<number>(0);
  const [modal_large, setmodal_large] = useState<boolean>(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  function tog_large(id: number) {
    setmodal_large(!modal_large);
    setImageId(id);
  }
  useEffect(() => {
    dispatch(onGetDirectContact());
    dispatch(getMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  const backToUserChat = () => {
    userChatShow.current.classList.remove("user-chat-show");
  };

  const toggleModal = () => {
    setView(false)
  };

  const viewDesign = () => {
    setView(true)
  }

  useEffect(() => {
    console.log("chat messages", chatMessages);
  }, [chatMessages]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (isLoading) {
        const totalDuration = 4 * 60 * 1000; // 4 minutes
        const intervalTime = totalDuration / 100; // Interval time for 1% progress
        interval = setInterval(() => {
            setProgress((prev) => {
                const newProgress = prev + 1;
                if (newProgress >= 100) {
                    clearInterval(interval);
                    setLoading(false);
                    return 100;
                }
                setCurrentMessageIndex(Math.floor(newProgress / 25));
                // if(newProgress%25 === 0)
                //   addResponse(chatMessages,messages[Math.floor(newProgress / 25)])
                return newProgress;
            });
        }, intervalTime); // Increment progress every intervalTime milliseconds
    }

    // Cleanup function to clear the interval
    return () => {
        if (interval) {
            clearInterval(interval);
            setCurrentMessageIndex(0);
        }
    };
}, [isLoading]);

const convertToCode = () => {
  navigate("/image_to_app", { state: apiResponse.response});
}

  const addDAMessage = async () => {
    if (curMessage === "") return;
    const messageIdToUpdate = 1;
    setLoading(true);
    const newMessage = {
      id: 1,
      from_id: 1,
      to_id: 2,
      msg: curMessage,
      reply: "",
      isImages: false,
      has_images: [],
      datetime: "",
    };
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    newMessage.datetime = `${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;

    const updatedChatMessages = chatMessages.map(
      (message: { id: number; usermessages: any }) =>
        message.id === messageIdToUpdate
          ? {
              ...message,
              usermessages: [...message.usermessages, newMessage],
            }
          : message
    );
    setChatMessages(updatedChatMessages);

    // const totalDuration = 4 * 60 * 1000; // 4 minutes
    //   const intervalTime = totalDuration / 100;
    //   let prev = 0 // Interval time for 1% progress
    //     const interval = setInterval(() => {
    //       const newProgress = prev + 1;
    //             if (newProgress >= 100) {
    //                 clearInterval(interval);
    //                 // setLoading(false);
    //                 // return 100;
    //             }
    //             setCurrentMessageIndex(Math.floor(newProgress / 25));
    //             if(newProgress%25 === 0)
    //               addResponse(updatedChatMessages,messages[Math.floor(newProgress / 25)])
          
    //     }, intervalTime);

    let furtherUpdatedChatMessages: [] = [];
    setTimeout(() => {
      const responseMsg = {
        id: Math.floor(Math.random() * 100),
        from_id: 2,
        to_id: 1,
        msg: "Please hold on while I work on a design.",
        reply: "",
        isImages: false,
        has_images: [],
        datetime: `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`,
      };
      furtherUpdatedChatMessages = updatedChatMessages.map(
        (message: { id: number; usermessages: any }) =>
          message.id === messageIdToUpdate
            ? {
                ...message,
                usermessages: [...message.usermessages, responseMsg],
              }
            : message
      );
      setChatMessages(furtherUpdatedChatMessages);
    }, 1000);

    const payload = { prompt: curMessage };
    try {
      const response: { status_code: number; response: any } = await axios.post(
        // "http://localhost:8000/create_wireframe",
        process.env.REACT_APP_MLO_SERVER + "create_wireframe",
        payload
      );
      console.log("response:", response);
      if (response.status_code !== 200) {
        console.log("There seems to be some error");
        addResponse(
          furtherUpdatedChatMessages,
          "Something went wrong, please try again!"
        );
        setLoading(false);
        setProgress(0);
        return;
      } else {
        console.log("wrong");
      }
      console.log(response);
      const design = "data:image/png;base64," + response.response.design;

      addResponse(furtherUpdatedChatMessages, "Here is your design", true, [
        { id: 1, image: design },
      ]);
      setApiResponse(response);
      console.log("response from mlo", response);
      setLoading(false);
      setProgress(0);
    } catch (error: any) {
      setLoading(false);
      setProgress(0);
      setTimeout(() => {
        addResponse(
          furtherUpdatedChatMessages,
          "Something went wrong, please try again",
          false
        );
      }, 20000);

      if (axios.isAxiosError(error)) {
        console.error("Error in API call:", error.message);
        addResponse(
          furtherUpdatedChatMessages,
          "Something went wrong, please try again!"
        );
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        }
      } else {
        console.error("Unexpected error:", error);
      }
    }

    setcurMessage("");
  };

  const addResponse = (
    messages: [],
    response: string,
    isImages = false,
    has_images: any = []
  ) => {
    let newMsg;
    const responseMsg = {
      id: Math.floor(Math.random() * 100),
      from_id: 2,
      to_id: 1,
      msg: response,
      reply: "",
      isImages: isImages,
      has_images: has_images,
      datetime: "",
    };
    newMsg = messages.map((message: { id: number; usermessages: any }) =>
      message.id === 1
        ? {
            ...message,
            usermessages: [...message.usermessages, responseMsg],
          }
        : message
    );

    setChatMessages(newMsg);
  };

  const chatRef = useRef<any>(null);
  const onKeyPress = (e: any) => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();
      setcurMessage(value);
      addDAMessage();
    }
  };

  const handleClick = (ele: HTMLElement) => {
    const copy = ele
      .closest(".chat-list")
      ?.querySelector(".ctext-content")?.innerHTML;
    if (copy) {
      navigator.clipboard.writeText(copy);
    }

    const copyClipboardElement = document.getElementById("copyClipBoard");
    if (copyClipboardElement) {
      copyClipboardElement.style.display = "block";
      setTimeout(() => {
        copyClipboardElement.style.display = "none";
      }, 500);
    }
  };

  const [emojiArray, setemojiArray] = useState<any>([]);
  const onEmojiClick = (event: any, emojiObject: any) => {
    setemojiArray([...emojiArray, emojiObject.emoji]);
    setcurMessage(curMessage + event.emoji);
  };

  const [grapesContent, setGrapesContent] = useState({ code: "", design: "" });
  const handleEditClick = async () => {
    setShowGrapesPage(true);

    if (apiResponse) {
      console.log("API response accessed in handleEditClick:", apiResponse);
      const { code, design } = apiResponse.response;
      setGrapesContent({ code, design });
    } else {
      console.log("No API response available");
    }
  };

  //   useEffect(() => {
  //     if (showGrapesPage && grapesContent.code && grapesContent.design) {
  //       console.log("Grapes content updated:", grapesContent);
  //     }
  //   }, [showGrapesPage, grapesContent]);

  if (showGrapesPage) {
    return (
      <GrapesJS
        html={grapesContent.code}
        cssUrl={grapesContent.design}
        setShowGrapes={setShowGrapesPage}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4">
            <div className="user-chat w-100 overflow-hidden" ref={userChatShow}>
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">
                    <div className="p-3 user-chat-topbar">
                      <Row className="align-items-center">
                        <Col sm={4} xs={8}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <Link
                                to="#"
                                onClick={backToUserChat}
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom"></i>
                              </Link>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  {Chat_Box_Image === undefined ? (
                                    <img
                                      src={userDummayImage}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={Chat_Box_Image}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  )}
                                  <span className="user-status"></span>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate mb-0 fs-16">
                                    <a
                                      className="text-reset username"
                                      data-bs-toggle="offcanvas"
                                      href="#userProfileCanvasExample"
                                      aria-controls="userProfileCanvasExample"
                                    >
                                      {Chat_Box_Username}
                                    </a>
                                  </h5>
                                  <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                    <small>
                                      {user_Status === null
                                        ? "24 Members"
                                        : user_Status}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* <Col className="text-end">
                                                    <button
                                                        onClick={convertToCode}
                                                        className="btn btn-edit-grape"
                                                    >
                                                        <i
                                                            className="ri-edit-2-line"
                                                            style={{ fontSize: "24px" }}
                                                        ></i>
                                                    </button>
                                                </Col> */}
                      </Row>
                    </div>

                    <div className="position-relative" id="users-chat">
                      <div
                        className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y"
                        id="chat-conversation"
                      >
                        <SimpleBar ref={chatRef} style={{ height: "100%" }}>
                          <ul
                            className="list-unstyled chat-conversation-list"
                            id="users-conversation"
                          >
                            {(chatMessages || []).map(
                              (message: userMessagesType) =>
                                message.usermessages.map(
                                  (userChat: UserMessage, key: number) => (
                                    <li
                                      className={
                                        userChat.to_id === 1
                                          ? "chat-list left"
                                          : "chat-list right"
                                      }
                                      key={key}
                                    >
                                      <div className="conversation-list">
                                        {message.sender === Chat_Box_Username &&
                                          userChat.to_id === 1 && (
                                            <div className="chat-avatar">
                                              {Chat_Box_Image === undefined ? (
                                                <img
                                                  src={userDummayImage}
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src={Chat_Box_Image}
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          )}
                                        <div className="user-chat-content">
                                          {" "}
                                          <div className="ctext-wrap">
                                            {userChat.isImages === true ? (
                                              <>
                                                <div className="message-img mb-0">
                                                  {userChat.has_images &&
                                                    userChat.has_images.map(
                                                      (
                                                        img: {
                                                          id: number;
                                                          image: string;
                                                        },
                                                        key: number
                                                      ) => (
                                                        <div key={key}>
                                                          <div>
                                                            <a
                                                              className="popup-img d-inline-block"
                                                              href={img.image}
                                                            >
                                                              <img
                                                                src={img.image}
                                                                alt=""
                                                                className="rounded border"
                                                              />
                                                            </a>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                <UncontrolledDropdown className="align-self-start message-box-drop">
                                                  <DropdownToggle
                                                    href="#"
                                                    className="btn nav-btn"
                                                    tag="a"
                                                  >
                                                    <i className="ri-more-2-fill"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {/* <DropdownItem href="#" className="reply-message" onClick={() => setreply({ sender: message.sender, msg: userChat.msg, id: userChat.id })}>
                                                                                                                <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                                                                                                Reply
                                                                                                            </DropdownItem> */}
                                                                                                            <DropdownItem href="#" onClick={viewDesign}>
                                                                                                                <i className="ri-eye-line me-2 text-muted align-bottom"></i>
                                                                                                                View
                                                                                                            </DropdownItem>
                                                                                                            {/* <DropdownItem href="#" onClick={(e: any) => handleClick(e.target)}>
                                                                                                                <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                                                                                                Copy
                                                                                                            </DropdownItem> */}
                                                    <DropdownItem
                                                      href="#"
                                                      onClick={handleEditClick}
                                                    >
                                                      <i className="ri-pencil-line me-2 text-muted align-bottom"></i>
                                                      Edit
                                                    </DropdownItem>
                                                    <DropdownItem href="#" onClick={() => convertToCode()}>
                                                                                                                <i className="ri-lightbulb-line me-2 text-muted align-bottom"></i>
                                                                                                                Convert to code
                                                                                                            </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </>
                                            ) : (
                                              <>
                                                <div className="ctext-wrap-content">
                                                  {userChat.reply ? (
                                                    <>
                                                      <div className="ctext-wrap-content">
                                                        <div className="replymessage-block mb-0 d-flex align-items-start">
                                                          <div className="flex-grow-1">
                                                            <h5 className="conversation-name">
                                                              {
                                                                userChat.reply
                                                                  .sender
                                                              }
                                                            </h5>
                                                            <p className="mb-0">
                                                              {
                                                                userChat.reply
                                                                  .msg
                                                              }
                                                            </p>
                                                          </div>
                                                          <div className="flex-shrink-0">
                                                            <button
                                                              type="button"
                                                              className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"
                                                            ></button>
                                                          </div>
                                                        </div>
                                                        <p className="mb-0 ctext-content mt-1">
                                                          {userChat.msg}
                                                        </p>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <p className="mb-0 ctext-content">
                                                      {userChat.msg}
                                                    </p>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          <div className="conversation-name">
                                            <small className="text-muted time">
                                              {userChat.datetime}
                                            </small>
                                            <span className="text-success check-message-icon">
                                              <i className="ri-check-double-line align-bottom"></i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )
                            )}
                          </ul>
                        </SimpleBar>
                      </div>
                      {isLoading && (
                        <div style={{ paddingLeft: 30, paddingBottom: 10 }}>
                          <div className="text-muted mb-0">
                            <p>{messages[currentMessageIndex]}</p>
                          </div>
                          <Progress value={progress} striped animated />
                          {/* <Card>
                                    <div className="live-preview">

                                        <Card className="bg-light overflow-hidden shadow-none">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0"><b className="text-secondary">{progress}% </b> {messages[currentMessageIndex]}</h6>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <h6 className="mb-0">1 min left</h6>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <div >
                                                <Progress value={progress} color="info" className="bg-secondary-subtle rounded-0" />
                                            </div>
                                        </Card>

                                        <Card className="bg-light overflow-hidden shadow-none">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0"><b className="text-success">60%</b> Update in
                                                            progress...</h6>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <h6 className="mb-0">45s left</h6>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <div>
                                                <Progress value={60} color="success" className="bg-success-subtle rounded-0" />
                                            </div>
                                        </Card>

                                        <Card className="bg-light overflow-hidden shadow-none">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0"><b className="text-danger">82%</b> Update in
                                                            progress...</h6>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <h6 className="mb-0">25s left</h6>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <div>
                                                <Progress value={82} color="danger" className="bg-danger-subtle rounded-0" />
                                            </div>
                                        </Card>

                                    </div>

                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ height: "275px" }}>
                                            <code>
                                                <ContentExample />
                                            </code>
                                        </pre>
                                    </div>
                                </CardBody>
                            </Card> */}
                        </div>
                      )}
                    </div>
                    <div className="chat-input-section p-3 p-lg-4">
                      <form id="chatinput-form">
                        <Row className="g-0 align-items-center">
                          <div className="col-auto">
                            <div className="chat-input-links me-2"></div>
                          </div>
                          <div className="col">
                            <div className="chat-input-feedback">
                              Please Enter a Message
                            </div>
                            <input
                              type="text"
                              value={curMessage}
                              onKeyDown={onKeyPress}
                              onChange={(e) => setcurMessage(e.target.value)}
                              className="form-control chat-input bg-light border-light"
                              id="chat-input"
                              placeholder="Type your message..."
                            />
                          </div>
                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">
                                <button
                                  type="button"
                                  disabled={curMessage === ""}
                                  onClick={() => {
                                    addDAMessage();
                                    setemojiArray("");
                                  }}
                                  className="btn btn-success chat-send waves-effect waves-light disable"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Modal isOpen={view} toggle={toggleModal} size="lg">
        <ModalHeader>Design Preview</ModalHeader>
        <ModalBody>
          {apiResponse?.response?.design && (
            <img
              src={"data:image/png;base64," + apiResponse.response.design}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default GenerateWireFrame;
